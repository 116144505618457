





























import httpClient from "@/services/httpClient";
import router from "@/router";
import Vue from "vue";

const waitingMessages = [
  "Dette går raskt, bare tell til 1000 mens du venter. 😇",
  "Vennligst vent mens vi justerer styrken på kaffetrakteren. ☕️",
  "Dette er den beste tiden å gjøre husarbeid på. 🧹",
  "Finner på nye måter å la deg vente på. 😈",
];

export default Vue.extend({
  name: "Room",
  data() {
    return {
      roomPath: "",
    };
  },
  computed: {
    getWaitingMessage() {
      return waitingMessages[Math.floor(Math.random() * waitingMessages.length)];
    }
  },
  async beforeMount() {
    // If the room name contains unsupported characters (., /, Æ, Ø, Å, etc), replace them with valid chars.
    const path = router.currentRoute.path.split("/")[1];

    const sanitizedPath = path
      .replace(new RegExp("æ", "g"), "ae")
      .replace(new RegExp("ø", "g"), "o")
      .replace(new RegExp("å", "g"), "aa")
      .replace(new RegExp(/[$-/:-?{-~!"^_`\[\]]/, "g"), ""); // eslint-disable-line

    if (sanitizedPath !== path) {
      router.push("/" + sanitizedPath);
    }
  },
  async mounted() {
    const path = router.currentRoute.path.split("/")[1];
    const whereByResponse = await httpClient.get(`/wherebyLink/${path}`);
    this.roomPath = whereByResponse.data.roomUrl + "?embed&roomIntegrations=on&lang=nb&chat=on&background=off&leaveButton=on&screenshare=on";
  },
});
